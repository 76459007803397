import React, { useState } from "react";
import "./CardSelector.css";
import { Checkbox } from "@mui/material";
import Badge from "./Badge";

function CardSelector({
  card__title,
  card__title2,
  card__description,
  card__badge,
  card__price,
  isSelected,
  onChange,
}) {
  return (
    <div
      className={`CardSelector ${isSelected ? "selected" : ""}`}
      onClick={onChange} // Appeler la fonction de changement de sélection
    >
      <div className="CardSelector__content">
        <div className="CardSelector__header">
          <div className="CardSelector__header__title">
            <h4>{card__title} - {card__title2}</h4>
          </div>
          <div className="CardSelector__header__selection">
            {isSelected && <div className="CardSelector__info">Selected</div>}
            <Checkbox checked={isSelected} onChange={onChange} />
          </div>
        </div>
        <p>{card__description}</p>
        <div className="CardSelector__bottomContainer">
          <div className="CardSelector__price">
            <p>
              <b>₹ {card__price}</b>
            </p>
          </div>
          <div className="CardSelector__badge">
            <div className="div">
              <Badge label={card__badge} />
            </div>
          </div>
        </div>

        <div className="h16"></div>
      </div>
    </div>
  );
}

export default CardSelector;
