import React, { useEffect, useState } from "react";
import "./Frenchquiz.css";
import "../../App.css";
import questions from "./questions.json";
import gz from "../../img/gz.png";
import quizz1 from "./quizz1.png";

import { ArrowBack, ArrowForward, TimerOutlined } from "@mui/icons-material";

import greylogo from "../../img/greylogo.png";
import CallToAction100 from "../../component/CallToAction100";

import { db } from '../../firebase'; // Assurez-vous que le chemin est correct
import { collection, addDoc } from 'firebase/firestore';
import Footer from "../Footer";

function FrenchQuiz() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState({});
  const [showScore, setShowScore] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [email, setEmail] = useState("");
  const [messageReponse, setMessageReponse] = useState("");
  const [loading, setLoading] = useState(true);
  const [testStarted, setTestStarted] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const [progress, setProgress] = useState(0);
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 400);

    return () => clearTimeout(timer);
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (responses[currentQuestionIndex]) {
      setSelectedOption(responses[currentQuestionIndex]);
    } else {
      setSelectedOption("");
    }

    setProgress(((currentQuestionIndex + 1) / questions.length) * 100);
  }, [currentQuestionIndex, responses]);

  const handleChange = (value) => {
    setSelectedOption(value);
  };

  const handleNext = () => {
    setResponses({
      ...responses,
      [currentQuestionIndex]: selectedOption,
    });

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setShowScore(true);
      handleSendEmail(); // Appel de la fonction d'envoi d'email
    }
  };

  const handlePrevious = () => {
    setResponses({
      ...responses,
      [currentQuestionIndex]: selectedOption,
    });

    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const calculateScore = () => {
    let score = 0;
    questions.forEach((question, index) => {
      const userAnswer = responses[index];
      if (userAnswer && userAnswer === question.answer) {
        score++;
      }
    });
    return score;
  };

  const getLevel = (score) => {
    if (score === questions.length) return "C1";
    if (score >= questions.length * 0.8) return "B2";
    if (score >= questions.length * 0.6) return "B1";
    if (score >= questions.length * 0.4) return "A2";
    return "A1";
  };
  const handleSendEmail = async () => {
    const score = calculateScore();
    const level = getLevel(score);

    if (!email) {
      setMessageReponse("");
      return;
    }

    try {
      const response = await fetch(
        "https://us-central1-lcfr-28a14.cloudfunctions.net/sendEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            score: score,
            level: level,
            totalQuestions: questions.length,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Error");
      }

      const resultat = await response.text(); // Récupère le message de succès
      setMessageReponse(resultat);
    } catch (erreur) {
      setMessageReponse(erreur.message);
    }
  };

  const handleStartTest = async () => {
    if (email) {
      if (validateEmail(email)) {
        setShowPopup(false); // Ferme la popup
        setTestStarted(true); // Démarre le test
        setEmailError(""); // Réinitialise le message d'erreur
        try {
          await addDoc(collection(db, 'emails__quizz'), {
            email: email,
            timestamp: new Date(),
          });
     
        } catch (error) {
          console.error("Error saving document: ", error);
        }
      } else {
        setEmailError("Please enter a valid e-mail");
      }
    } else {
      setEmailError("Please enter an e-mail");
    }
  };

  return (
    <div className="FrenchQuiz">
      {showPopup && (
        <div className="FrenchQuiz__popup">
          <div className="FrenchQuiz__popup-content">
            <div className="FrenchQuiz__Popup__quizz__logo">
              <img src={greylogo} alt="" />
            </div>
            <div className="FrenchQuiz__Popup__quizz__txt">
              <h2>Measure your French proficiency!</h2>
              <p>Enter your email to get your detailed results</p>
            </div>

            <div className="FrenchQuiz__Popup__quizz__block">
              <div className="FrenchQuiz__Popup__quizz__input">
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                />
                {emailError && <p style={{ color: "red" }}>{emailError}</p>}
              </div>

                <div className="FrenchQuiz__Popup__quizz__block__cta">
                  <div className="Frenchquizz__align">
                    <CallToAction100
                      onClick={handleStartTest}
                      label="Start test"
                      bg="#ff6b00"
                    />{" "}
                  </div>
                </div>
              </div>
            {messageReponse && <p>{messageReponse}</p>}
            <div className="FrenchQuiz__Popup__quizz__bottom__block">
              <div className="FrenchQuiz__Popup__quizz__timer">
                <div className="FrenchQuiz__Popup__quizz__timer__icon">
                  <TimerOutlined />
                </div>
                <p>Test duration : 6 minutes</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Appliquez la classe floue uniquement au contenu principal */}
      <div className={`${showPopup ? "blur" : ""}`}>
        <div className="progress-bar">
          <div
            className="progress-bar__fill"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        {showScore ? (
          <div className="FrenchQuiz__result">
            <div className="FrenchQuiz__result__img">
              <img src={gz} alt="" className="" />
            </div>

            <div className="FrenchQuiz__result__container">
              <h2>Congratulations</h2>
              <div className="FrenchQuiz__result__container__tagline">
                <p>You have completed the test!</p>
              </div>
            </div>
            <div className="FrenchQuiz__result__content">
             
              {messageReponse && <p>{messageReponse}</p>}
              <div className="h64"></div>
              <a href="/our_courses">
              <CallToAction100 label="View available courses" /></a>
            </div>
            <Footer />
        
          </div>
        ) : (
          <div className="Frenchquiz__questions__container">
            <div className="Frenchquiz__questionslayout">
              <div className="Frenchquiz__amount">
                <p>
                  {currentQuestionIndex + 1} / {questions.length}
                </p>
              </div>

              <div className="Frenchquiz__question__content">
                <h2>{questions[currentQuestionIndex].question}</h2>
              </div>
              <div className="Frenchquiz__img">
                {loading ? (
                  <div className="Frenchquiz_img_skeleton"></div>
                ) : (
                  <img
                    src={questions[currentQuestionIndex].image}
                    alt="question"
                  />
                )}
              </div>

              {questions[currentQuestionIndex].options.map((option, i) => (
                <div
                  key={i}
                  className={`Frenchquiz__option ${
                    selectedOption === option ? "selected" : ""
                  }`}
                  onClick={() => handleChange(option)}
                >
                  {option}
                </div>
              ))}
            </div>
            {/* Cache le bloc inférieur lorsque la popup est affichée */}
            {!showPopup && (
              <div className="Frenchquiz__bottomblock">
                {currentQuestionIndex > 0 && (
                  <button
                    className="Frenchquiz__button"
                    onClick={handlePrevious}
                  >
                    <div className="Frenchquiz__button__icon__previous">
                      <ArrowBack />
                    </div>
                    <p>Back</p>
                  </button>
                )}

                <button
                  className={`Frenchquiz__button ${
                    !selectedOption ? "disabled" : ""
                  }`}
                  onClick={handleNext}
                  disabled={!selectedOption}
                >
                  <p>
                    {currentQuestionIndex < questions.length - 1
                      ? "Next"
                      : "Finish"}
                  </p>
                  <div className="Frenchquiz__button__icon__next">
                    <ArrowForward />
                  </div>
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function Frenchquiz() {
  return (
    <div className="Frenchquiz">
      <FrenchQuiz />
    </div>
  );
}

export default Frenchquiz;
