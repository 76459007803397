import React from "react";
import "./Comparison.css";
import ComparisonTable from "./ComparisonTable";

function Comparison() {
  return (
    <div className="Comparison">
      <div className="Comparison__title">
        <div className="Comparison__title__h2">
          <h2>Why ? Because language learning tools are <span>boring</span> 🥱</h2>
        </div>
        <div className="Comparison__positioning__redblock">
          <div className="Comparison__redbackground"></div>
        </div>
      </div>

      <div className="Comparison__subtitle">
        <div className="Comparison__subtitle1">
          <p>all the same</p>
        </div>

        <div className="Comparison__subtitle2">
          <p>theoritical</p>
        </div>
      </div>
      <ComparisonTable />
    </div>
  );
}

export default Comparison;
