import React from "react";
import "./Home.css";
import Footer from "../../Sections/Footer";
import Headerhome from "../../Sections/HomeComponents/Headerhome";
import ValueProposition from "./ValueProposition";
import OurClasses from "./OurClasses";
import OurLevels from "./OurLevels";
import FrenchTest from "./FrenchTest";
import Comparison from "./Comparison";
import Sentence from "./Sentence";
import Prosandcons from "./Prosandcons";
import Sentence2 from "./Sentence2";
import Pricing from "./Pricing";
import FAQ from "./FAQ";
import FreeClass from "./FreeClass";
import FreeTrial from "../../component/FreeTrial";
import { motion } from "framer-motion";

function Home() {
  const fadeInUp = {
    hidden: { opacity: 1, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  };
  return (
    <div className="Home">
      <div className="Home__content">
        <Headerhome />
        <FreeTrial />
        <ValueProposition />
        <OurClasses />
        <OurLevels />
        <FrenchTest />
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.01 }}
          variants={fadeInUp}
        >
          <Comparison />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.01 }}
          variants={fadeInUp}
        >
        <Sentence />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.01 }}
          variants={fadeInUp}
        >
        <Prosandcons />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.01 }}
          variants={fadeInUp}
        >
        <Sentence2 />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.01 }}
          variants={fadeInUp}
        >
        <Pricing />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.01 }}
          variants={fadeInUp}
        >
        <FAQ />
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.01 }}
          variants={fadeInUp}
        >
        <FreeClass />
        </motion.div>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
