import React, { useEffect, useState } from "react";
import { db, auth } from "../../firebase"; // Assurez-vous que l'objet auth est importé depuis Firebase
import { collection, getDocs, addDoc, query, orderBy, doc, getDoc, onSnapshot } from "firebase/firestore";
import { formatDistanceToNow } from "date-fns";

function Documents() {
  const [students, setStudents] = useState([]);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [userName, setUserName] = useState(""); // État pour stocker le nom de l'utilisateur

  useEffect(() => {
    // Récupère le nom de l'utilisateur authentifié à partir de Firestore
    const fetchUserName = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userRef = doc(db, "users", user.uid);
          const userSnapshot = await getDoc(userRef);
          if (userSnapshot.exists()) {
            setUserName(userSnapshot.data().name); // Définit le nom de l'utilisateur
          } else {
            console.log("No user data found");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    const fetchStudents = async () => {
      const studentsCollection = collection(db, "Programs", "Youngs A2.1", "students");
      const studentsSnapshot = await getDocs(studentsCollection);
      const studentsList = studentsSnapshot.docs.map((doc) => doc.data());
      setStudents(studentsList);
    };

    // Utilisation de onSnapshot pour écouter les messages en temps réel
    const fetchMessages = () => {
      const messagesCollection = collection(db, "Programs", "Youngs A2.1", "messages");
      const q = query(messagesCollection, orderBy("timestamp", "desc"));
      
      // Écouter les changements en temps réel
      onSnapshot(q, (querySnapshot) => {
        const messagesList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setMessages(messagesList);
      });
    };

    fetchUserName();
    fetchStudents();
    fetchMessages();
  }, []);

  const handleSendMessage = async () => {
    if (message.trim()) { // Vérifie que le message n'est pas vide
      const messagesCollection = collection(db, "Programs", "Youngs A2.1", "messages");

      await addDoc(messagesCollection, {
        text: message,
        name: userName, // Ajout du nom de l'utilisateur dans le document
        timestamp: new Date()
      });

      setMessage(""); // Efface le champ du message après l'envoi
    }
  };

  return (
    <div>
      <b>
        <p>Voici les étudiants de la classe Youngs A2.1</p>
      </b>
      {students.map((student, index) => (
        <p key={index}>
          {student.name} {student.surname}
        </p>
      ))}
      <hr />
      <p>Écrivez quelque chose à cette classe</p>
      <input 
        type="text" 
        placeholder="Votre message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <button onClick={handleSendMessage}>Send</button>
      <div>
        <h3>Messages</h3>
        {messages.map((msg) => (
          <div key={msg.id}>
            <p><strong>{msg.name}</strong>: {msg.text}</p>
            <small>{formatDistanceToNow(new Date(msg.timestamp.toDate()))} ago</small>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Documents;
