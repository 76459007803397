import React from "react";
import "./Examens.css"

function Examens() {
  return <div className="Examens">
    <h2>Examens</h2>
    
  </div>;
}

export default Examens;
