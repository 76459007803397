import React from "react";
import "./Sentence2.css";
import CTA from "../../component/CTA";
function Sentence2() {
  return (
    <div className="Sentence2">
      <div className="Sentence2__title">
        <div className="Sentence2__title__h2">
          <h2>
            French classes your kid will love, <span>guaranteed</span>
          </h2>
        </div>
        <div className="Sentence2__positioning__whiteblock">
          <div className="Sentence2__whitebackground"></div>
        </div>
      </div>
      <div className="Sentence2__subtitle">
        <div className="Sentence2__subtitle1">
          <p>
            We all know that mastering French requires more than just apps and
            books.
            <br /> Real progress comes from real conversations.
          </p>
        </div>
        <div className="Sentence2__subtitle2">
          <p>
            Enter Le Cercle Français. Our online group classes and one-on-one
            tutoring sessions provide 
            <br />
            the practice your child needs to truly
            speak French with confidence.
          </p>
        </div>
      </div>
      <div className="Sentence2__cta">
        <CTA label="View our courses" />
      </div>
    </div>
  );
}

export default Sentence2;
