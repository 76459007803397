import React from "react";
import "./CTAPortal.css";

function CTAPortal(props) {
  const Icon = props.icon; // Utilisation de la prop icon

  return (
    <button
      className={`CTAPortal ${props.disabled ? "disabled" : ""}`}
      style={{ backgroundColor: props.bg || "#EBEBEE" }}
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <div className="CTAPortal_icon">
        {Icon && <Icon />} {/* Rendu de l'icône dynamique */}
      </div>
      <p>{props.label}</p>
    </button>
  );
}

export default CTAPortal;
