import React from "react";
import Headerhome from "../../Sections/HomeComponents/Headerhome";
import Footer from "../../Sections/Footer";

function Shipping() {
  return (
    <div className="Shipping">
      <Headerhome />
      <div className="Margin__global">
        <h2>Shipping and Delivery</h2>

        <p>Last Updated: October 21, 2024</p>

        <p>
          Welcome to Le Cercle Français! 😊 We are excited to have you join our
          online French courses. Please read the following information regarding
          the access and delivery of our services.
        </p>

        <h2>1. Online Courses Access</h2>

        <p>
          Since our courses are conducted online, there is no physical shipping
          involved. Upon successful registration and payment, you will receive
          access to our online learning platform.
        </p>

        <h2>2. Access Details</h2>

        <p>
          <strong>2.1 Access Credentials:</strong> After registration, you will
          receive an email containing your login credentials for our online
          platform. This email will be sent to the email address provided during
          registration.
        </p>

        <p>
          <strong>2.2 Platform Features:</strong> Our online platform includes
          course materials, video lessons, assignments, and interactive features
          to enhance your learning experience. You can access the platform
          anytime from any device with an internet connection.
        </p>

        <h2>3. Delivery Time</h2>

        <p>
          <strong>3.1 Immediate Access:</strong> Once your payment is confirmed,
          you will receive access credentials within 24 hours.
        </p>

        <p>
          <strong>3.2 Delays:</strong> In rare cases of technical issues or
          payment processing delays, access credentials may take up to 48 hours
          to be delivered. If you do not receive your access credentials within
          this timeframe, please contact us at{" "}
          <a href="mailto:contact@lecerclefrancais.in">
            contact@lecerclefrancais.in
          </a>
          .
        </p>

        <h2>4. Technical Support</h2>

        <p>
          <strong>4.1 Assistance:</strong> If you encounter any technical
          difficulties accessing the online platform, our support team is here
          to help. Please contact us at{" "}
          <a href="mailto:contact@lecerclefrancais.in">
            contact@lecerclefrancais.in
          </a>{" "}
          for assistance.
        </p>

        <p>
          <strong>4.2 Platform Maintenance:</strong> We periodically perform
          maintenance on our platform to improve performance and features.
          Scheduled maintenance times will be communicated in advance, and we
          strive to minimize any disruption to your learning experience.
        </p>

        <h2>5. Contact Information</h2>

        <p>
          If you have any questions or need further assistance, please do not
          hesitate to contact us at{" "}
          <a href="mailto:contact@lecerclefrancais.in">
            contact@lecerclefrancais.in
          </a>
          . We are here to support you every step of the way! 😊
        </p>

        <p>
          Thank you for choosing Le Cercle Français for your French learning
          journey! 🇫🇷
        </p>
      </div>
      <div className="h64"></div>
      <Footer />
    </div>
  );
}

export default Shipping;
