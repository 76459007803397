import React from "react";
import CTA from "../../component/CTA";
import arrowlook from "../../img/arrowlook.svg";
import "./FreeClass.css";
import { AutoAwesome } from "@mui/icons-material";

function FreeClass() {
  return (
    <div className="FreeClass">
      <div className="FreeClass__h2__mobile__align">
        <h2>Real conversations, real progress</h2>
      </div>

      <div className="FreeClass__center">
        <img src={arrowlook} alt="" />
      </div>

      <div className="FreeClass__zone">
        <div className="FreeClass__input">
          <input
            type="text"
            className="FreeClass_Mail_input"
            placeholder="Enter your email address"
          />
        </div>
        <div className="FreeClass__CTA">
          <CTA label="Claim your free class" />
        </div>
      </div>
      <div className="FreeClass__sentence">
        <div className="FreeClass__text__icon__ai">
          <AutoAwesome />
        </div>

        <p>Get your private class, 100% free</p>
      </div>
    </div>
  );
}

export default FreeClass;
