import React from "react";
import "./FrenchTest.css";
import tiger from "../../img/tiger.png";
import CTA from "../../component/CTA";
import arrowlook2 from "../../img/arrowlook2.svg"
import { motion } from "framer-motion";
function FrenchTest() {
  const fadeInUp = {
    hidden: { opacity: 1, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  };
  return (
    <div className="FrenchTest">
      <div className="FrenchTest__container">
        <div className="FrenchTest__img">
          <img src={tiger} alt="" />
        </div>
        <div className="FrenchTest__h2">
          <h2>Don't know your child's level?</h2>
        </div>
        <div className="FrenchTest__subtitle">
          <p>Evaluate Your Child's French Level for Free</p>
        </div>
        <div className="FrenchTest__button">
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.01 }}
            variants={fadeInUp}
          >
          <CTA label="Take the French test" />
          </motion.div>
        </div>
        <div className="FrenchTest__arrow">
            <img src={arrowlook2} alt="" />
        </div>
      </div>
    </div>
  );
}

export default FrenchTest;
