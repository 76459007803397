import React from "react";
import "./SidePannel.css";
import logo_lcf from "../../img/logo_lcf.png";
import UserProfile from "./UserProfile";

function SidePannel({ selectedMenu, setSelectedMenu }) {
  return (
    <div className="SidePannel">
      <div className="SidePannel__logo">
        <img src={logo_lcf} alt="" />
      </div>
      <div className="SidePannel__greyline"></div>
      <div className="SidePannel__menu">
        <p
          className={selectedMenu === "Etudiants" ? "active" : "inactive"}
          onClick={() => setSelectedMenu("Etudiants")}
        >
          Etudiants
        </p>
        <p
          className={selectedMenu === "Classes" ? "active" : "inactive"}
          onClick={() => setSelectedMenu("Classes")}
        >
          Toutes les classes
        </p>
        <p
          className={selectedMenu === "Documents" ? "active" : "inactive"}
          onClick={() => setSelectedMenu("Documents")}
        >
          Documents
        </p>
        <p
          className={selectedMenu === "Examens" ? "active" : "inactive"}
          onClick={() => setSelectedMenu("Examens")}
        >
          Examens
        </p>
        <p
          className={selectedMenu === "Agenda" ? "active" : "inactive"}
          onClick={() => setSelectedMenu("Agenda")}
        >
          Agenda
        </p>
        <p
          className={selectedMenu === "Actualite" ? "active" : "inactive"}
          onClick={() => setSelectedMenu("Actualite")}
        >
          Actualité
        </p>
      </div>
      <div className="SidePannel__bottomInfo">
        <UserProfile />
      </div>
    </div>
  );
}

export default SidePannel;
