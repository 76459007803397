import React, { useState, useEffect, useContext } from "react";
import Home from "./Pages/Home/Home.js"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";

import CookiesPopup from "./component/CookiesPopup";
import { CookieConsentProvider } from "./CookieConsentContext";

import Frenchquizlanding from "./Sections/Frenchquiz/Frenchquizlanding";
import Frenchquiz from "./Sections/Frenchquiz/Frenchquiz";
import Ourcourses from "./Sections/Ourcourses/Ourcourses";
import Aboutus from "./Pages/Aboutus";
import Careers from "./Pages/Careers";
import EnrollAdults from "./Pages/Courses/EnrollAdults";
import EnrollPiouPiou from "./Pages/Courses/EnrollPiouPiou";
import EnrollKids from "./Pages/Courses/EnrollKids";
import EnrollYoungs from "./Pages/Courses/EnrollYoungs";
import EnrollTuition from "./Pages/Courses/EnrollTuition";
import EnrollProfessional from "./Pages/Courses/EnrollProfessional";

import ContactUs from "./Pages/ContactUs";

import Dashboard from "./portal/PortalContent/Dashboard";
import Login from "./portal/Login";
import { AuthContext, AuthProvider } from "./AuthContext";
import EmptyPlaceholder from "./EmptyPlaceholder";
import {
  bach,
  description_pioupiou,
  ages_pioupiou,
  durations_pioupiou,
  sessions_pioupiou,
  participants_pioupiou,
  price_pioupiou,
  coursename_pioupiou,
  description_kids,
  ages_kids,
  durations_kids,
  sessions_kids,
  participants_kids,
  coursename_kids,
  price_kids,
  description_youngs,
  ages_youngs,
  durations_youngs,
  sessions_youngs,
  participants_youngs,
  price_youngs,
  coursename_youngs,
  description_tuitions,
  ages_tuitions,
  durations_tuitions,
  sessions_tuitions,
  participants_tuitions,
  price_tuitions,
  coursename_tuitions,
  description_adults,
  ages_adults,
  durations_adults,
  sessions_adults,
  participants_adults,
  price_adults,
  coursename_adults,
  description_pros,
  ages_pros,
  durations_pros,
  sessions_pros,
  participants_pros,
  price_pros,
  coursename_pros,
} from "./Data";
import Enroll from "./Pages/Courses/Enroll";
import Test from "./Test";
import FreeTrialBook from "./Pages/FreeTrialBook";
import AdminDashboard from "./portal/AdminDashboard";
import Privacypolicy from "./Pages/Legal/Privacypolicy";
import Cookiepolicy from "./Pages/Legal/Cookiepolicy";
import TermsAndConditions from "./Pages/Legal/TermsAndConditions";
import Shipping from "./Pages/Legal/Shipping";
import Cancellation from "./Pages/Legal/Cancellation";

function PrivateRoute({ children }) {
  const { isAuthenticated, loading } = useContext(AuthContext);
  if (loading) return <EmptyPlaceholder />;
  return isAuthenticated ? children : <Navigate to="/login" replace />;
}

function App() {
  return (
    <div className="App">
      <CookieConsentProvider>
        <CookiesPopup />
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  coursename_pioupiou={coursename_pioupiou}
                  participants_pioupiou={participants_pioupiou}
                  ages_pioupiou={ages_pioupiou}
                  coursename_kids={coursename_kids}
                  ages_kids={ages_kids}
                  participants_kids={participants_kids}
                  coursename_youngs={coursename_youngs}
                  ages_youngs={ages_youngs}
                  participants_youngs={participants_youngs}
                />
              }
            />
            <Route path="/free_trial" element={<FreeTrialBook />} />
            <Route path="/privacy" element={<Privacypolicy />} />
            <Route path="/cookies" element={<Cookiepolicy />} />
            <Route
              path="/Terms_and_conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/Cancellation_and_refund" element={<Cancellation />} />
            <Route path="/Shipping_and_delivery" element={<Shipping />} />
            <Route path="/Contact_us" element={<ContactUs />} />
            <Route path="/Test" element={<Test />} />
            <Route
              path="/Evaluate_your_french_level_Le_Cercle_Francais"
              element={<Frenchquizlanding />}
            />
            <Route
              path="/Evaluate_your_french_level_Le_Cercle_Francais_Quizz"
              element={<Frenchquiz />}
            />
            <Route
              path="/our_courses"
              element={
                <Ourcourses
                  bach={bach}
                  description_pioupiou={description_pioupiou}
                  ages_pioupiou={ages_pioupiou}
                  durations_pioupiou={durations_pioupiou}
                  sessions_pioupiou={sessions_pioupiou}
                  participants_pioupiou={participants_pioupiou}
                  price_pioupiou={price_pioupiou}
                  coursename_pioupiou={coursename_pioupiou}
                  description_kids={description_kids}
                  ages_kids={ages_kids}
                  durations_kids={durations_kids}
                  sessions_kids={sessions_kids}
                  participants_kids={participants_kids}
                  price_kids={price_kids}
                  coursename_kids={coursename_kids}
                  description_youngs={description_youngs}
                  ages_youngs={ages_youngs}
                  durations_youngs={durations_youngs}
                  sessions_youngs={sessions_youngs}
                  participants_youngs={participants_youngs}
                  price_youngs={price_youngs}
                  coursename_youngs={coursename_youngs}
                  description_tuitions={description_tuitions}
                  ages_tuitions={ages_tuitions}
                  durations_tuitions={durations_tuitions}
                  sessions_tuitions={sessions_tuitions}
                  participants_tuitions={participants_tuitions}
                  price_tuitions={price_tuitions}
                  coursename_tuitions={coursename_tuitions}
                  description_adults={description_adults}
                  ages_adults={ages_adults}
                  durations_adults={durations_adults}
                  sessions_adults={sessions_adults}
                  participants_adults={participants_adults}
                  price_adults={price_adults}
                  coursename_adults={coursename_adults}
                  description_pros={description_pros}
                  ages_pros={ages_pros}
                  durations_pros={durations_pros}
                  sessions_pros={sessions_pros}
                  participants_pros={participants_pros}
                  price_pros={price_pros}
                  coursename_pros={coursename_pros}
                />
              }
            />
            <Route path="/about_us" element={<Aboutus />} />
            <Route path="/careers" element={<Careers />} />
            <Route
              path="/PiouPiou_Course"
              element={
                <EnrollPiouPiou
                  description_pioupiou={description_pioupiou}
                  ages_pioupiou={ages_pioupiou}
                  durations_pioupiou={durations_pioupiou}
                  sessions_pioupiou={sessions_pioupiou}
                  participants_pioupiou={participants_pioupiou}
                  price_pioupiou={price_pioupiou}
                  coursename_pioupiou={coursename_pioupiou}
                />
              }
            />
            <Route
              path="/Kids_Course"
              element={
                <EnrollKids
                  description_kids={description_kids}
                  ages_kids={ages_kids}
                  durations_kids={durations_kids}
                  sessions_kids={sessions_kids}
                  participants_kids={participants_kids}
                  price_kids={price_kids}
                  coursename_kids={coursename_kids}
                />
              }
            />
            <Route
              path="/Youngs_Course"
              element={
                <EnrollYoungs
                  description_youngs={description_youngs}
                  ages_youngs={ages_youngs}
                  durations_youngs={durations_youngs}
                  sessions_youngs={sessions_youngs}
                  participants_youngs={participants_youngs}
                  price_youngs={price_youngs}
                  coursename_youngs={coursename_youngs}
                />
              }
            />
            <Route
              path="/Tuitions_Course"
              element={
                <EnrollTuition
                  description_tuitions={description_tuitions}
                  ages_tuitions={ages_tuitions}
                  durations_tuitions={durations_tuitions}
                  sessions_tuitions={sessions_tuitions}
                  participants_tuitions={participants_tuitions}
                  price_tuitions={price_tuitions}
                  coursename_tuitions={coursename_tuitions}
                />
              }
            />
            <Route
              path="/Adults_Course"
              element={
                <EnrollAdults
                  description_adults={description_adults}
                  ages_adults={ages_adults}
                  durations_adults={durations_adults}
                  sessions_adults={sessions_adults}
                  participants_adults={participants_adults}
                  price_adults={price_adults}
                  coursename_adults={coursename_adults}
                />
              }
            />
            <Route
              path="/Professional_Course"
              element={
                <EnrollProfessional
                  description_pros={description_pros}
                  ages_pros={ages_pros}
                  durations_pros={durations_pros}
                  sessions_pros={sessions_pros}
                  participants_pros={participants_pros}
                  price_pros={price_pros}
                  coursename_pros={coursename_pros}
                />
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/Enroll" element={<Enroll />} />

            <Route
              path="/Dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/AdminDashboard"
              element={
                <PrivateRoute>
                  <AdminDashboard />
                </PrivateRoute>
              }
            />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </CookieConsentProvider>
    </div>
  );
}

export default () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);
