import React from "react";
import "./Ourcourses.css";
import Headerhome from "../HomeComponents/Headerhome";
import Footer from "../Footer";




function Ourcourses({
  bach,

  description_pioupiou,
  ages_pioupiou,
  durations_pioupiou,
  sessions_pioupiou,
  participants_pioupiou,
  price_pioupiou,
  coursename_pioupiou,

  description_kids,
  ages_kids,
  durations_kids,
  sessions_kids,
  participants_kids,
  price_kids,
  coursename_kids,

  description_youngs,
  ages_youngs,
  durations_youngs,
  sessions_youngs,
  participants_youngs,
  price_youngs,
  coursename_youngs,

  description_tuitions,
  ages_tuitions,
  durations_tuitions,
  sessions_tuitions,
  participants_tuitions,
  price_tuitions,
  coursename_tuitions,



}) {
  return (
    <div className="Ourcourses">
      <Headerhome />
  
      <div className="Ourcourses__head">
        <h2>Kids French classes 🇫🇷</h2>
      </div>
      <a href="/PiouPiou_Course">
       
      </a>
      <a href="/Kids_Course">
       
      </a>
      <a href="/Youngs_Course">
       
      </a>
      <a href="/Tuitions_Course">
      
      </a>
     
  
    
      <div className="h64"></div>
      <Footer />
    </div>
  );
}

export default Ourcourses;
