import { Instagram } from "@mui/icons-material";
import React from "react";
import "../App.css";
import "./Footer.css";
import logoLCFR from "../img/logoLCFR.png";

function Footer() {
  return (
    <div className="Footer">
      <div className="Footer__greylinetop"></div>
      <div className="Footer__margin">
        <div className="Footer__container">
          <div className="Footer__flex1">
            <a href="/">
              <div className="logoLCFR">
                <img src={logoLCFR} alt="Le Cercle Français Logo" />
              </div>
            </a>
            <p>French Made Fun, Just for Kids</p>

            <p>Copyright © 2024 - All rights reserved</p>
            <div className="Container__stroke">Designed in 🇫🇷</div>
          </div>
          <div className="Footer__flex2">
            <div className="Footer__title">
              <p>LINKS</p>
            </div>
            <div className="Footer__content">
              <a href="/">
                <p>Home</p>
              </a>

              <a href="/our_courses">
                <p>Our French courses</p>
              </a>

              <a href="/login">
                <p>Login</p>
              </a>
              <a href="/about_us">
                <p>About</p>
              </a>
              <a href="/careers">
                <p>Careers</p>
              </a>
              <a href="/Contact_us">
                <p>Contact us</p>
              </a>
            </div>
          </div>
          <div className="Footer__flex3">
            <div className="Footer__title">
              <p>LEGAL</p>
            </div>
            <div className="Footer__content">
              <a href="/Terms_and_conditions">
                <p>Terms and Conditions</p>
              </a>
              <a href="/privacy">
                <p>Privacy Policy</p>
              </a>

              <a href="/cookies">
                <p>Cookie Policy</p>
              </a>
              <a href="/Cancellation_and_refund">
                <p>Cancellation and Refund</p>
              </a>

              <a href="/Shipping_and_delivery">
                <p>Shipping and delivery</p>
              </a>
            </div>
          </div>
          <div className="Footer__flex4">
            <div className="Footer__title">
              <p>CONTACT</p>
            </div>
            <div className="Footer__content">
              <p>91 87880 97983</p>
              <p>contact@lecerclefrancais.in</p>
              <a href="https://www.instagram.com/lecerclefrancais.pune/">
                <div className="Footer__white insta">
                  <Instagram
                    style={{
                      fontSize: "16px",
                      marginLeft: "-2px",
                    }}
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
