import React from "react";
import "./OurLevels.css";
import { motion } from "framer-motion";

function OurLevels() {
  const fadeInUp = {
    hidden: { opacity: 1, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  };
  return (
    <div className="OurLevels">
      <h2>Our levels</h2>
      <div className="OurLevels__subtitle">
        <p>
          From begginer to advanced, our courses are divided in 4 differents
          levels. <br />
          We teach French till B2 level.
        </p>
      </div>
      <div className="OurLevels__align__center">
        <div className="OurLevels__align">
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.01 }}
            variants={fadeInUp}
          >
          <div className="OurLevel__block">
            <div className="OurLevel__block__left">
              <div className="OurLevel__block__square1">
                <p>A1</p>
              </div>
            </div>
            <div className="OurLevel__block__right">
              <h3>Absolute begginer</h3>
              <p>Simple words and sentences</p>
            </div>
          </div>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.01 }}
            variants={fadeInUp}
          >
          <div className="OurLevel__block">
            <div className="OurLevel__block__left">
              <div className="OurLevel__block__square2">
                <p>A2</p>
              </div>
            </div>
            <div className="OurLevel__block__right">
              <h3>Begginer</h3>
              <p>Simple interactions</p>
            </div>
          </div>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.01 }}
            variants={fadeInUp}
          >
          <div className="OurLevel__block">
            <div className="OurLevel__block__left">
              <div className="OurLevel__block__square3">
                <p>B1</p>
              </div>
            </div>
            <div className="OurLevel__block__right">
              <h3>Intermediary</h3>
              <p>Daily conversations</p>
            </div>
          </div>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.01 }}
            variants={fadeInUp}
          >
          <div className="OurLevel__block">
            <div className="OurLevel__block__left">
              <div className="OurLevel__block__square4">
                <p>B2</p>
              </div>
            </div>
            <div className="OurLevel__block__right">
              <h3>Intermediary advanced</h3>
              <p>Complex topics</p>
            </div>
          </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default OurLevels;
