import React from "react";
import "./Frenchquizlanding.css";

import testfr from "../../img/testfr.png";
import Footer from "../Footer";
import Headerhome from "../HomeComponents/Headerhome";
import { CheckCircle } from "@mui/icons-material";
import CallToAction100 from "../../component/CallToAction100";

function Frenchquizlanding({ onStart }) {
  return (
    <div className="Frenchquizlanding">
      <Headerhome />
      <div className="Frenchquizlanding__img">
        <img src={testfr} alt="" />
      </div>
      <div className="Frenchquizcontent">
        <h2>Measure Your Skills in French with Our Online Test</h2>

        <p>
        In just 6 minutes, assess your comprehension, grammar, and vocabulary (from A1 to C1 level).
        </p>

        <h2>Why take this French test?</h2>
        <div className="Frenchquiz__objectives">
          <div className="Frenchquiz__objectives__icon">
            <CheckCircle />
          </div>
          <p>
            <b>Quick and easy: </b>An accessible format that allows you to test yourself independently.
          </p>
        </div>
        <div className="Frenchquiz__objectives">
          <div className="Frenchquiz__objectives__icon">
            <CheckCircle />
          </div>
          <p>
            <b> Accurate assessment:</b> Discover your proficiency level in French, ideal for determining your placement in our courses at Le Cercle Français.
          </p>
        </div>
        <div className="Frenchquiz__objectives">
          <div className="Frenchquiz__objectives__icon">
            <CheckCircle />
          </div>
          <p>
            <b> Immediate results:</b> Receive a clear overview of your level and language skills instantly by email.
          </p>
        </div>
        <div className="h32"></div>
        <div className="Frenchquiz__cta__placement">
          <a href="/Evaluate_your_french_level_Le_Cercle_Francais_Quizz">
            <CallToAction100 label="Start the test" bg="#ff6b00" />
          </a>{" "}
        </div>
        <div className="h64"></div>
      </div>
      <Footer />
    </div>
  );
}

export default Frenchquizlanding;
