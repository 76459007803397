import React from "react";
import "./CardClass.css";
import Badge from "../../component/Badge";
import { ArrowForwardIos } from "@mui/icons-material";

function CardClass(props) {
  return (
    <div className="CardClass">
      <div className="CardClass__left">
        <img src={props.img} alt="" />
      </div>
      <div className="CardClass__right">
        <div className="CardClass__right__title">
          <h3>{props.title}</h3>
        </div>

        <div className="CardClass__right__subtitle">
          <p>{props.subtitle}</p>
        </div>
        <div className="CardClass__right__badge">
          <Badge label={props.badge} />
        </div>
        <div className="CardClass__right__bottom">
          <div className="CardClass__right__bottom__arrow">
            <ArrowForwardIos style={{fontSize: "18px"}} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardClass;
