import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { Timestamp } from 'firebase/firestore'; // Assurez-vous d'importer Timestamp
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "./Etudiants.css";


function Etudiants() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollection);

        const usersList = usersSnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: data.createdAt instanceof Timestamp
              ? data.createdAt.toDate().toLocaleString()
              : 'N/A',
            PaymentStatusBadge: data.PaymentStatus === 'Paid' ? 'Paid' : 'Pending', // On passe "Paid" ou "Pending"
          };
        });

        // Filtrer uniquement les utilisateurs avec le statut de paiement "Paid"
        const filteredUsersList = usersList.filter(user => user.PaymentStatusBadge === 'Paid');

        setUsers(filteredUsersList);
      } catch (error) {
        console.error('Erreur de récupération des utilisateurs:', error);
        setError('Une erreur est survenue lors de la récupération des utilisateurs.');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const columnDefs = [
    { headerName: 'Prénom', field: 'name', sortable: true, filter: true, width: 150 },
    { headerName: 'Nom', field: 'surname', sortable: true, filter: true, width: 150 },
    { headerName: 'Niveau', field: 'selectedCourse', sortable: true, filter: true, width: 90 },
    { headerName: 'Cours', field: 'coursename', sortable: true, filter: true, width: 90 },
    {
      headerName: 'Payment', // Colonne qui affiche le badge
      field: 'PaymentStatusBadge', // Utilisation de "PaymentStatusBadge"
      sortable: true,
      filter: true,
      width: 120,
      cellClass: (params) => {
        switch (params.value) {
          case 'Paid':
            return 'status-paid';
          case 'Pending':
            return 'status-pending';
       
        }
      },
    },
    { headerName: 'Dates du cours', field: 'course_dates', sortable: true, filter: true, width: 240 },
    { headerName: 'Email', field: 'email', sortable: true, filter: true, width: 180 },
    { headerName: 'Téléphone', field: 'phone', sortable: true, filter: true, width: 140 },
    { headerName: 'Montant du paiement', field: 'payment_amount', sortable: true, filter: true, width: 150 },
    { headerName: 'Date de création', field: 'createdAt', sortable: true, filter: true, width: 180 },
    { headerName: 'ID Utilisateur', field: 'id', sortable: true, filter: true, width: 150 },
  ];

  if (loading) {
    return <p>loading...</p>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>{error}</p>;
  }

  return (
    <div className="Etudiants">
      <h2>Etudiants</h2>
      <div className="h48"></div>
      {users.length === 0 ? (
        <p>Aucun utilisateur trouvé.</p>
      ) : (
        <div className="ag-theme-alpine" style={{ height: '1000px', width: '100%' }}>
          <AgGridReact
            rowData={users}
            columnDefs={columnDefs}
            pagination={true}
            domLayout="autoHeight"
          />
        </div>
      )}
    </div>
  );
}

export default Etudiants;
