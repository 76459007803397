// src/Dashboard.js
import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Box,
  List,
  ListItem,
  AppBar,
  Toolbar,
} from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { getFirestore, doc, getDoc } from "firebase/firestore";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "./Dashboard.css";

import PostStudent from "./PostStudent";

function Dashboard() {
  const navigate = useNavigate(); // Use useNavigate hook
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userSurname, setUserSurname] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [courseDates, setCourseDates] = useState("");

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      setUserEmail(user.email); // Set user email
      const db = getFirestore();
      const fetchUserData = async () => {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          setUserName(userDoc.data().name); // Set user name
          setUserSurname(userDoc.data().surname); // Set user surname
          setSelectedCourse(userDoc.data().selectedCourse);
          setCourseDates(userDoc.data().course_dates);
        }
      };
      fetchUserData();
    } else {
      navigate("/login"); // Use navigate to redirect to login if no user
    }

    // Update the current time every second
    const updateTime = () => {
      const date = new Date();
      const options = {
        timeZone: "Europe/Paris",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      setCurrentTime(date.toLocaleTimeString("fr-FR", options));
    };

    const timerId = setInterval(updateTime, 1000);
    return () => clearInterval(timerId);
  }, [navigate]);

  const handleLogout = async () => {
    const auth = getAuth();
    await signOut(auth); // Sign out the user
    navigate("/login"); // Use navigate to redirect to login page after logout
    window.location.reload(); // Refresh the page to reset the application state
  };

  return (
    <div className="Dashboard">
      <button onClick={handleLogout}>LOGOUT</button>

      {userEmail && <p>Logged in as: {userEmail}</p>}
      {userName && userSurname && (
        <p>
          {userName} {userSurname}
          <br />
          Course : {selectedCourse}
          <br />
          Dates : {courseDates}
          <br />
          PARIS TIME {currentTime}
        </p>
      )}

      <PostStudent />
    </div>
  );
}

export default Dashboard;
