import React, { useState } from "react";
import Headerhome from "../../Sections/HomeComponents/Headerhome";
import Footer from "../../Sections/Footer";
import { useNavigate } from "react-router-dom";
import "./Enroll.css";

import {
  AccessTime,
  CalendarToday,

} from "@mui/icons-material";
import CallToAction100 from "../../component/CallToAction100";

import foot from "../../img/foot.png";
import CardSelector from "../../component/CardSelector";


function Enrollyoungs({
  price_youngs,
  description_youngs,
  ages_youngs,
  durations_youngs,
  sessions_youngs,
  participants_youngs,
  coursename_youngs,
}) {
  const navigate = useNavigate();
  const [selectedCourse, setSelectedCourse] = useState("A1.1"); // Default selection is A1.1

  const handlePayment = async () => {
    navigate("/Enroll", {
      state: {
        price: price_youngs,
        description: description_youngs,
        ages: ages_youngs,
        durations: durations_youngs,
        sessions: sessions_youngs,
        participants: participants_youngs,
        coursename: coursename_youngs,
        selectedCourse: selectedCourse, // Passer la sélection du cours
      },
    });
    window.location.reload(); // Redemander un rafraîchissement après la navigation
  };
  const handleCourseSelection = (course) => {
    if (selectedCourse === course) {
      setSelectedCourse(null); // Unselect the course if it is already selected
    } else {
      setSelectedCourse(course); // Select the course
    }
  };

  return (
    <div className="Enroll__container">
      <Headerhome />

      <div className="Enroll__layout">
        <div className="Margin__global">
          <div className="Enroll__block">
            <div className="Enroll__headingimg">
              <div className="Enroll__img">
                <img src={foot} alt="" />
              </div>

              <div className="Enroll__title__desc">
                <h2>{coursename_youngs}</h2>
                <p>{description_youngs}</p>
                <div className="Enroll__courseinfo">
                  <div className="icon__txt">
                    <div className="icon__txt__icon">
                      <CalendarToday />
                    </div>
                    <div className="icon__txt__txt">
                      <p>{durations_youngs}</p>
                    </div>
                  </div>
                  <div className="icon__txt">
                    <div className="icon__txt__icon">
                      <AccessTime />
                    </div>
                    <div className="icon__txt__txt">
                      <p>{sessions_youngs}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="h16"></div>

            <h3>Available courses</h3>
            <p>Select a course to become a student</p>
            <div className="h16"></div>
            <CardSelector
              card__title="A1.1"
              card__title2={coursename_youngs}
              card__price={price_youngs}
              card__description="A1.1 level of the CEFR (common european framework of reference for languages) is designed for absolute beginners and focuses on helping learners navigate simple, everyday interactions in French. In this course, you'll be introduced to the basics of the French language."
              card__badge="9 sits available"
              isSelected={selectedCourse === "A1.1"} // Comparer la sélection
              onChange={() => handleCourseSelection("A1.1")} // Passer le nom du cours
            />
            <div className="h16"></div>
            <CardSelector
              card__title="A1.2"
              card__title2={coursename_youngs}
              card__price={price_youngs}
              card__description="A1.2 level of the CEFR builds on the foundation set in A1.1, guiding learners through more advanced beginner topics to enhance their ability to interact in French."
              card__badge="12 sits available"
              isSelected={selectedCourse === "A1.2"} // Comparer la sélection
              onChange={() => handleCourseSelection("A1.2")} // Passer le nom du cours
            />
            <div className="h16"></div>
            <CardSelector
              card__title="A2.1"
              card__title2={coursename_youngs}
              card__price={price_youngs}
              card__description="A2.1 course is designed for young learners who have either completed the Ados A1 level or hold a DELF A1 Junior certification. This intermediate level builds on basic language skills, helping students express themselves with greater clarity and depth."
              card__badge="22 sits available"
              isSelected={selectedCourse === "A2.1"} // Comparer la sélection
              onChange={() => handleCourseSelection("A2.1")} // Passer le nom du cours
            />
            <div className="h16"></div>
            <CardSelector
              card__title="A2.2"
              card__title2={coursename_youngs}  
              card__price={price_youngs}
              card__description="A2.2 course is the second part of the A2 level, aimed at young learners who have completed Ados A2.1 or hold a DELF A2 Junior certification. This level enhances your ability to express your thoughts and experiences in more complex ways."
              card__badge="22 sits available"
              isSelected={selectedCourse === "A2.2"} // Comparer la sélection
              onChange={() => handleCourseSelection("A2.2")} // Passer le nom du cours
            />
            <div className="h48"></div>
            <CallToAction100
              disabled={!selectedCourse}
              label="Become a student"
              bg="var(--primary)"
              onClick={handlePayment}
            />
            <div className="h24"></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Enrollyoungs;
