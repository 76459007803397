import React, { useState, useEffect } from "react";
import Userinfo from "./Userinfo";
import Payment from "./Payment";
import AccountCreated from "./AccountCreated";
import { useLocation } from "react-router-dom";
import Headerhome from "../../Sections/HomeComponents/Headerhome";
import Footer from "../../Sections/Footer";

function Enroll() {
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [userInfo, setUserInfo] = useState(null);


  const [price, setPrice] = useState(null);
  const [description, setDescription] = useState(null);
  const [ages, setAges] = useState(null);
  const [durations, setDurations] = useState(null);
  const [sessions, setSessions] = useState(null);
  const [participants, setParticipants] = useState(null);
  const [coursename, setCoursename] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    const {
      price,
      description,
      ages,
      durations,
      sessions,
      participants,
      coursename,
      selectedCourse,
    } = location.state || {};

    // Set individual values
    setPrice(price);
    setDescription(description);
    setAges(ages);
    setDurations(durations);
    setSessions(sessions);
    setParticipants(participants);
    setCoursename(coursename);
    setSelectedCourse(selectedCourse);
  }, [location.state]);

  const nextStep = (info, uid) => {
    setUserInfo({ ...info, uid });
    setStep(step + 1);
  };
  console.log("Selected Course:", selectedCourse);

  return (    
    <div>
      <Headerhome />
      {step === 1 && (
        <Userinfo
          price={price}
          coursename={coursename}
          durations={durations}
          nextStep={nextStep}
          selectedCourse={selectedCourse}
        />
      )}
      {step === 2 && (
        <Payment
          userInfo={userInfo}
          userId={userInfo?.uid} 
          nextStep={() => setStep(3)}
          price={price}
          description={description}
          ages={ages}
          durations={durations}
          sessions={sessions}
          participants={participants}
          coursename={coursename}
          selectedCourse={selectedCourse}
        />
      )}
      {step === 3 && (
        <AccountCreated
          userInfo={userInfo}
          userId={userInfo?.uid} 
          price={price}
          description={description}
          ages={ages}
          durations={durations}
          sessions={sessions}
          participants={participants}
          coursename={coursename}
          selectedCourse={selectedCourse}
        />
      )}
      <Footer />
    </div>
  );
}

export default Enroll;
