import { doc, updateDoc } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import db from "../../firebase";
import { CheckCircle } from "@mui/icons-material";
import CallToAction100 from "../../component/CallToAction100";
import CallToAction100Outlined from "../../component/CallToAction100Outlined";
import "./AccountCreated.css";

function AccountCreated({ userInfo, userId, price, coursename, selectedCourse }) {
  const [messageReponse, setMessageReponse] = useState("");
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    if (userId) {
      const userDocRef = doc(db, "users", userId);
      updateDoc(userDocRef, {
        PaymentStatus: "Paid",
      }).catch((error) => {
        console.error("Error updating document: ", error);
        console.log(userId);
      });
    }
  }, [userId]);

  useEffect(() => {
    handleSendEmail();
  }, []);

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    setCurrentDate(formattedDate);
  }, []);

  const handleSendEmail = async () => {
    try {
      const response = await fetch(
        "https://us-central1-lcfr-28a14.cloudfunctions.net/sendPaymentSuccessEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: userInfo.email,
            name: userInfo.name,
            paymentAmount: price,
            coursename: coursename,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Erreur lors de l'envoi de l'email.");
      }

      const resultat = await response.text();
      setMessageReponse(resultat);
    } catch (erreur) {
      setMessageReponse(erreur.message); // Afficher l'erreur dans l'UI
    }
  };

  return (
    <div className="AccountCreated">
      <div className="AccountCreated__icon">
        <CheckCircle />
      </div>
      <div className="AccountCreated__txt">
        <h2>Payment successful</h2>
        <p>Thank you for your payment, your account has been created.</p>
        <span>
          <p>INR {price}｜{currentDate}</p>
        </span>
      </div>

      <div className="AccountCreated__buttons">
        <a href="/login">
          <CallToAction100 label="Login" />
        </a>
        <div className="h16"></div>
        <a href="/">
          <CallToAction100Outlined label="Return to home" />
        </a>
      </div>
      <div className="h120"></div>
      <div className="h120"></div>
    </div>
  );
}

export default AccountCreated;
