import React, { useEffect, useRef, useState } from "react";
import { auth, db } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import CallToAction100 from "../../component/CallToAction100";
import "./UserInfo.css";
import Signup from "../../img/Signup.png";
import ReCAPTCHA from "react-google-recaptcha";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { serverTimestamp } from "firebase/firestore";
import "../../Sections/HomeComponents/ContactZone.css";

function Userinfo({ nextStep, price, coursename, durations, selectedCourse}) {
  const [consent1, setConsent1] = useState(false);
  const [consent2, setConsent2] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [errors, setErrors] = useState({});
  const [userInfo, setUserInfo] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "", // Ajout de la confirmation du mot de passe
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  function onCaptchaChange(value) {
    console.log("Captcha value:", value);
    setCaptchaValue(value);
    setErrors({ ...errors, captcha: "" }); // Clear captcha error when it's solved
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear error for the field being edited
  };

  const validateFields = () => {
    let newErrors = {};
    if (!userInfo.name) newErrors.name = "Name is required";
    if (!userInfo.surname) newErrors.surname = "Surname is required";
    if (!userInfo.email) newErrors.email = "Email is required";
    if (!userInfo.phone) newErrors.phone = "Phone number is required";
    if (!userInfo.password) newErrors.password = "Password is required";
    if (userInfo.password !== userInfo.confirmPassword)
      newErrors.confirmPassword = "Passwords do not match";
    if (!captchaValue) newErrors.captcha = "Captcha is required"; // Check captcha
    return newErrors;
  };

  const handleSubmit = async () => {
    const newErrors = validateFields();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        userInfo.email,
        userInfo.password
      );
      const user = userCredential.user;

      // Check if user is created
      if (!user) {
        throw new Error("User creation failed");
      }

      // Reference to the Firestore document
      const userDocRef = doc(db, "users", user.uid); // Correctement utilisé

      // Save user info in Firestore
      await setDoc(userDocRef, {
        name: userInfo.name,
        surname: userInfo.surname,
        email: userInfo.email,
        phone: userInfo.phone,
        role: "student",
        payment_amount: price,
        coursename: coursename,
        selectedCourse: selectedCourse,
        createdAt: serverTimestamp(),
        course_dates: durations,
      });

      nextStep(userInfo, user.uid); // Pass user info to the next step
    } catch (error) {
      console.error("Error creating user: ", error);
      if (error.code === "auth/email-already-in-use") {
        setErrors({
          email: "Email already in use. Please use a different email.",
        });
      } else {
        alert("An error occurred: " + error.message);
      }
    }
  };



  return (
    <div className="UserInfo">
      <div className="UserInfo__blocks">
        <div className="UserInfo__left">
          <img src={Signup} alt="" />
        </div>
        <div className="UserInfo__right">
          <h2>Become a student</h2>
          <div className="UserInfo__input">
            <p>Name</p>
            <input
              type="text"
              name="name"
              value={userInfo.name}
              onChange={handleChange}
              style={{ borderColor: errors.name ? "red" : "black" }}
            />
            {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
          </div>
          <div className="UserInfo__input">
            <p>Surname</p>
            <input
              type="text"
              name="surname"
              value={userInfo.surname}
              onChange={handleChange}
              style={{ borderColor: errors.surname ? "red" : "black" }}
            />
            {errors.surname && <p style={{ color: "red" }}>{errors.surname}</p>}
          </div>
          <div className="UserInfo__input">
            <p>Email</p>
            <input
              type="text"
              name="email"
              value={userInfo.email}
              onChange={handleChange}
              style={{ borderColor: errors.email ? "red" : "black" }}
            />
            {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
          </div>
          <div className="UserInfo__input">
            <p>Phone number</p>
            <input
              type="text"
              name="phone"
              value={userInfo.phone}
              onChange={handleChange}
              style={{ borderColor: errors.phone ? "red" : "black" }}
            />
            {errors.phone && <p style={{ color: "red" }}>{errors.phone}</p>}
          </div>
          <div className="UserInfo__input" style={{ position: "relative" }}>
            <p>Password</p>
            <input
              type={showPassword ? "text" : "password"} // Utilise showPassword pour basculer entre text et password
              name="password"
              value={userInfo.password}
              onChange={handleChange}
              style={{ borderColor: errors.password ? "red" : "black" }}
            />
            <IconButton
              onClick={handleClickShowPassword}
              edge="end"
              style={{
                position: "absolute",
                right: "12px",
                bottom: "-2px",
                transform: "scale(0.8)",
                color: "#D3D3D3",
              }}
              tabIndex={-1}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
            {errors.password && (
              <p style={{ color: "red" }}>{errors.password}</p>
            )}
          </div>

          <div className="UserInfo__input" style={{ position: "relative" }}>
            <p>Confirm Password</p>
            <input
              type={showConfirmPassword ? "text" : "password"} // Utilise showConfirmPassword pour basculer entre text et password
              name="confirmPassword"
              value={userInfo.confirmPassword}
              onChange={handleChange}
              style={{ borderColor: errors.confirmPassword ? "red" : "black" }}
            />
            <IconButton
              onClick={handleClickShowConfirmPassword}
              edge="end"
              style={{
                position: "absolute",
                right: "12px",
                bottom: "-2px",
                transform: "scale(0.8)",
                color: "#D3D3D3",
              }}
              tabIndex={-1}
            >
              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
            {errors.confirmPassword && (
              <p style={{ color: "red" }}>{errors.confirmPassword}</p>
            )}
          </div>

          <div className="V1Contact__consent">
            <div className="V1Contact__container">
              <div className="V1Contact__icon">
                <input
                  type="checkbox"
                  id="consent1"
                  checked={consent1}
                  onChange={() => setConsent1(!consent1)}
                />
              </div>
              <div className="V1Contact__text legaltxt">
                <p>
                  By continuing you agree to Le Cercle Français{" "}
                  <a href="/Terms_and_conditions">
                    {" "}
                    <span className="V1Contact__legal">Terms & Conditions</span>
                  </a>{" "}
                  and{" "}
                  <a href="/privacy">
                    <span className="V1Contact__legal">Privacy Policy</span>
                  </a>
                </p>
              </div>
            </div>
            <div className="V1Contact__container">
              <div className="V1Contact__icon">
                <input
                  type="checkbox"
                  id="consent2"
                  checked={consent2}
                  onChange={() => setConsent2(!consent2)}
                />
              </div>
              <div className="V1Contact__text legaltxt">
                <p>
                  I want to receive information later about the institute
                  regarding my question
                </p>
              </div>
            </div>
            <div className="Captcha">
              <ReCAPTCHA
                sitekey="6Lc0AkUpAAAAACvpTUucFpGovUxv-_FJ-qkmXJ2T"
                onChange={onCaptchaChange}
              />
              {errors.captcha && (
                <p style={{ color: "red" }}>{errors.captcha}</p>
              )}
            </div>
            <CallToAction100 onClick={handleSubmit} label="Next step" />
            <div className="Userinfo__login">
              <p>
                Already have an account ?{" "}
                <a href="/login">
                  <span className="V1Contact__legal">Login</span>
                </a>
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Userinfo;
