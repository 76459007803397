import React, { useState, useEffect } from "react";
import mots from "./Motdujour.json"; // Importation directe du fichier JSON
import "./Motdujour.css";
function MotDuJour() {
  const [motDuJour, setMotDuJour] = useState("");

  useEffect(() => {
    // Calcul du jour de l'année (1-365)
    const currentDate = new Date();
    const startOfYear = new Date(currentDate.getFullYear(), 0, 0);
    const diff = currentDate - startOfYear;
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);

    const motIndex = dayOfYear % mots.mots.length;

    const mot = mots.mots[motIndex];
    setMotDuJour(mot);
  }, []);

  return (
    <div className="Motdujour">
      <div className="Motdujour__contenu">
        <p> 📝 Le mot du jour est : {motDuJour}</p>
      </div>
    </div>
  );
}

export default MotDuJour;
