import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { doc, getFirestore, setDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDHEKGAlKRC0tQlt8vNXyOa6945M40VnWE",
  authDomain: "lcfr-28a14.firebaseapp.com",
  projectId: "lcfr-28a14",
  storageBucket: "lcfr-28a14.appspot.com",
  messagingSenderId: "537735809518",
  appId: "1:537735809518:web:445cf118abaee931cc66ff",
  measurementId: "G-FKWR96G90P",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app);
const auth = getAuth(app); // Initialize Firebase Auth

export { auth, db }; // Export both db and auth

export default db;

