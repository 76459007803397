import React from "react";
import "./Sentence.css";

function Sentence() {
  return (
    <div className="Sentence">
      <h2>🥐🇫🇷🥖</h2>
      <div className="Sentence__title">
        <h2>Authentic lessons, Not just Tap and Swipe</h2>
      </div>

      <div className="Sentence__subtitle">
        <p>
          Done with monotonous apps and expensive lessons? Engage your child in
          genuine conversations, not automated responses. <br />
          Register to our group
          class or personalized tutoring that you won't find anywhere else.
        </p>
      </div>
    </div>
  );
}

export default Sentence;
