import React, { useState, useEffect } from "react";
import { db } from "../../../firebase"; // Assurez-vous que Firebase est correctement configuré
import {
  getDocs,
  collection,
  query,
  where,
  setDoc,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";

function YoungsA12() {
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    const fetchFilteredUsers = async () => {
      const usersRef = collection(db, "users");
      const q = query(
        usersRef,
        where("PaymentStatus", "==", "Paid"),
        where("coursename", "==", "Youngs"),
        where("selectedCourse", "==", "A1.2")
      );

      const querySnapshot = await getDocs(q);
      const users = querySnapshot.docs.map((doc) => ({
        uid: doc.id,
        ...doc.data(),
      }));
      setFilteredUsers(users);

      // Mettre à jour la collection "Youngs A2.1" dans la base de données "Programs"
      const programsRef = collection(db, "Programs", "Youngs A1.2", "students");
      users.forEach(async (user) => {
        const userDoc = doc(programsRef, user.uid);
        await setDoc(userDoc, {
          uid: user.uid,
          name: user.name,
          surname: user.surname,
        });
     
      });
    };

    // Utiliser onSnapshot pour écouter les changements en temps réel
    const usersRef = collection(db, "users");
    const q = query(
      usersRef,
      where("PaymentStatus", "==", "Paid"),
      where("coursename", "==", "Youngs"),
      where("selectedCourse", "==", "A1.2")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const users = querySnapshot.docs.map((doc) => ({
        uid: doc.id,
        ...doc.data(),
      }));
      setFilteredUsers(users);

      // Mettre à jour la collection "Youngs A2.1" dans la base de données "Programs"
      const programsRef = collection(db, "Programs", "Youngs A1.2", "students");
      users.forEach(async (user) => {
        const userDoc = doc(programsRef, user.uid);
        await setDoc(userDoc, {
          uid: user.uid,
          name: user.name,
          surname: user.surname,
        });
    
      });
    });

    // Nettoyage de l'abonnement onSnapshot lors du démontage du composant
    return () => unsubscribe();
  }, []);

  return (
    <div>
      <hr />
      <h3>Youngs A1.2</h3>

      {filteredUsers.length > 0 ? (
        filteredUsers.map((user, index) => (
          <p key={index}>
            {user.name} {user.surname}
          </p>
        ))
      ) : (
        <li>No student</li>
      )}
    </div>
  );
}

export default YoungsA12;
