import React from "react";
import "./ComparisonTable.css";
import { CheckCircle, Close } from "@mui/icons-material";
import duolingo from "../../img/duoligo.png";
import babel from "../../img/babel.png";
import book from "../../img/book.png";

function ComparisonTable() {
  return (
    <div className="ComparisonTable">
      <div className="ComparisonTable__flex">
        <div className="ComparisonTable__line1">
          <div className="ComparisonTable__title__emptyblock"></div>
          <div className="ComparisonTable__title1">
            <p>ENGAGING</p>
          </div>
          <div className="ComparisonTable__title2">
            <p>EASY</p>
          </div>
          <div className="ComparisonTable__title3">
            <p>COMPREHENSIVE</p>
          </div>
        </div>

        <div className="ComparisonTable__greyline"></div>

        <div className="ComparisonTable__line">
          <div className="ComparisonTable__logo">
            <img src={duolingo} alt="" />
          </div>
          <div className="ComparisonTable__icon__check">
            <CheckCircle />
          </div>
          <div className="ComparisonTable__icon__check">
            <CheckCircle />
          </div>
          <div className="ComparisonTable__icon__close">
            <Close />
          </div>
        </div>

        <div className="ComparisonTable__greyline"></div>

        <div className="ComparisonTable__line">
          <div className="ComparisonTable__logo">
            <img src={babel} alt="" />
          </div>
          <div className="ComparisonTable__icon__check">
            <CheckCircle />
          </div>
          <div className="ComparisonTable__icon__check">
            <CheckCircle />
          </div>
          <div className="ComparisonTable__icon__close">
            <Close />
          </div>
        </div>

        <div className="ComparisonTable__greyline"></div>

        <div className="ComparisonTable__line">
          <div className="ComparisonTable__logo">
            <img src={book} alt="" />
     
          </div>
          <div className="ComparisonTable__icon__close">
            <Close />
          </div>
          <div className="ComparisonTable__icon__check">
            <CheckCircle />
          </div>
          <div className="ComparisonTable__icon__close">
            <Close />
          </div>
        </div>

        {/* 
      <p>ENGAGING</p>
      <p>EASY</p>
      <p>COMPREHENSIVE</p>

      <img src={duolingo} alt="" />
      <CheckCircle />
      <CheckCircle />
      <Close />

      <img src={babel} alt="" />
      <CheckCircle />
      <CheckCircle />
      <Close />

      <img src={book} alt="" />
      <Close />
      <CheckCircle />
      <Close /> */}
      </div>
    </div>
  );
}

export default ComparisonTable;
