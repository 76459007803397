import React from 'react'
import './Agenda.css'

function Agenda() {
  return (
<div className="Agenda">
  <h2>Agenda</h2>
</div>
  )
}

export default Agenda