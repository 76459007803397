import React from "react";
import YoungsA21 from "./ProgramCreation/YoungsA21";
import YoungsA22 from "./ProgramCreation/YoungsA22";
import YoungsA12 from "./ProgramCreation/YoungsA12";
import YoungsA11 from "./ProgramCreation/YoungsA11";

function Classes() {
  return (
    <div>
      <h2>Toutes les classes</h2>
      <YoungsA11 />
      <YoungsA12 />
      <YoungsA21 />
      <YoungsA22 />
    </div>
  );
}

export default Classes;
