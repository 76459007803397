import React from 'react'
import './LoadingComponent.css'
import logo from '../img/logo.png'

function LoadingComponent() {
  return (
   <div className="LoadingComponent">
    <div className="LoadingComponent__centercontent">
  
        <img src={logo} alt="" />
        <div className="LoadingComponent__bar">
            <div className="LoadingComponent__bar__loaded">
                
            </div>
        </div>
    </div>
   </div>
  )
}

export default LoadingComponent