import React, { useState, useRef, useEffect } from "react";
import "./Pricing.css";

import PricingYoungs from "./PricingYoungs"


function Pricing() {
  return (
    <div className="Pricing">
   
      <PricingYoungs />
    </div>
  );
}

export default Pricing;
