import React from "react";
import Headerhome from "../../Sections/HomeComponents/Headerhome";
import Footer from "../../Sections/Footer";

function Cookiepolicy() {
  return (
    <div>
      <Headerhome />
      <h1>Cookie Policy</h1>
      <br />
      <p>
        This Cookie Policy explains what cookies are and how we use them on our
        website. You should read this policy to understand what cookies are, how
        we use them, the types of cookies we use, the information we collect
        using cookies, and how that information is used, and how to control
        cookie preferences. For further information on how we use, store, and
        keep your personal data secure, see our Privacy Policy.
      </p>
      <p>
        Your consent applies to the following domain: lecerclefrancais.com What
        are cookies?
      </p>
      <p>
        Cookies are small text files used to store small pieces of information.
        They are stored on your device when the website is loaded on your
        browser. These cookies help us make the website function properly, make
        it more secure, provide a better user experience, and understand how the
        website performs and to analyze what works and where it needs
        improvement.
      </p>
      <br />
      <h2>How do we use cookies?</h2>
      <p>
        As with most online services, our website uses first-party and
        third-party cookies for several purposes. First-party cookies are mostly
        necessary for the website to function the right way, and they do not
        collect any of your personally identifiable data.
      </p>
      <p>
        The third-party cookies used on our website are mainly for understanding
        how the website performs, how you interact with our website, keeping our
        services secure, providing advertisements that are relevant to you, and
        overall providing you with a better and improved user experience and
        help speed up your future interactions with our website. What types of
        cookies do we use?
      </p>
      <p>
        Essential: Some cookies are essential for you to be able to experience
        the full functionality of our site. They allow us to maintain user
        sessions and prevent any security threats. They do not collect or store
        any personal information. Statistics: These cookies store information
        like the number of visitors to the website, the number of unique
        visitors, which pages of the website have been visited, the source of
        the visit, etc. These data help us understand and analyze how well the
        website performs and where it needs improvement.
      </p>
      <p>
        {" "}
        Marketing: Our website displays advertisements. These cookies are used
        to personalize the advertisements that we show to you so that they are
        meaningful to you. These cookies also help us track the efficiency of
        these ad campaigns.
      </p>
      <br />
      <h2>How to control cookie preferences?</h2>
      <p>
        You can manage your cookie preferences by clicking on the "Privacy &
        Cookie Policy" tab on your screen. This will display the consent notice
        again enabling you to change your preferences or withdraw your consent
        entirely.
      </p>
      <p>
        In addition to this, different browsers provide different methods to
        block and delete cookies used by websites. You can change the settings
        of your browser to block/delete the cookies. To find out more about how
        to manage and delete cookies, visit wikipedia.org,
        www.allaboutcookies.org.
      </p>
      <br />
      <h2>Contact us</h2>
      <p>
        If you have any questions about our cookie policy, you can contact us:
      </p>
      <p>
        <b> By email: contact@lecerclefrancais.in</b>
      </p>
      <p> This cookie policy was last updated on 24/04/2024</p>
      <Footer />
    </div>
  );
}

export default Cookiepolicy;
