import React from "react";
import "./CTA.css";
import { ArrowForward } from "@mui/icons-material";

function CTA(props) {

  const Icon = props.icon || ArrowForward 

  return (
    <button
      className={`CTA ${props.disabled ? 'disabled' : ''}`} // Fusionner les classes ici
      style={{ backgroundColor: props.bg || 'var(--dark)' }}
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled} // Ajoutez la gestion du désactivation
    >
      <p style={{ color: props.color || 'white' }}>{props.label}</p>
      
    </button>
  );
}

export default CTA;
