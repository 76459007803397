import React, { useEffect, useRef, useState } from "react";
import Headerhome from "../../Sections/HomeComponents/Headerhome";
import Footer from "../../Sections/Footer";
import { useNavigate } from "react-router-dom"; // Modification ici
import "./Enroll.css";
import Badge from "../../component/Badge";
import { CheckCircle } from "@mui/icons-material";
import CallToAction100 from "../../component/CallToAction100";
import Dropdown from "../../component/Dropdown";
import piou from "../../img/piou.png";

function EnrollPiouPiou({
  price_pioupiou,
  description_pioupiou,
  ages_pioupiou,
  durations_pioupiou,
  sessions_pioupiou,
  participants_pioupiou,
  coursename_pioupiou
}) {
  const navigate = useNavigate(); // Changement ici
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const scrollRef = useRef(null);

  const handlePayment = () => {
    navigate("/Enroll", { // Changement ici
      state: {
        price: price_pioupiou,
        description: description_pioupiou,
        ages: ages_pioupiou,
        durations: durations_pioupiou,
        sessions: sessions_pioupiou,
        participants: participants_pioupiou,
        coursename: coursename_pioupiou
      },
    });


    window.location.reload();
  };
  
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = scrollRef.current.scrollTop;

      if (currentScrollY < 900) {
        setIsButtonVisible(true);
      } else {
        setIsButtonVisible(false);
      }
    };

    const scrollElement = scrollRef.current;
    scrollElement.addEventListener("scroll", handleScroll);

    return () => {
      scrollElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

 
 

  const courseItems1 = [
    "Understanding the letters of the French alphabet",
    "Sounds of each letter",
    "Letter identification games",
  ];

  const courseItems2 = [
    "Numbers",
    "Months and days and hours",
    "Time and space games",
  ];

  const courseItems3 = [
    "Naming fruits",
    "Naming vegetables",
    "Cooking and kitchen vocabulary",
  ];

  const courseItems4 = [
    "Identifying basic colors",
    "Naming objects of different colors",
    "Drawing game",
  ];

  const courseItems5 = [
    "Naming sports",
    "Popular sports in France",
    
  ];

  const courseItems6 = [
    "The farm animals",
    "The jungle animals",
    "Pets",
  ];

  const courseItems7 = [
    "Naming different rooms in the house",
    "Describing the purpose of each room",
    "House furniture",
  ];

  const courseItems8 = [
    "Classroom-related vocabulary",
    "Common actions: Listen, Write, Read and Learn",
  ];

  const courseItems9 = [
    "Weather vocabulary",
    "Discussing daily weather conditions.",
    "Seasons of the year",
  ];

  const courseItems10 = [
    "Identifying family members",
    "Describing relationships",

  ];



  return (
    <div
    className="EnrollPiouPiou"
    ref={scrollRef}
    style={{ height: "100vh", overflowY: "scroll" }} 
  >
      <Headerhome />
      
      <div className="Enroll__layout">
        <div className={`Sticky__button ${isButtonVisible ? "visible" : ""}`}>
          <CallToAction100
            className="Sticky__button__align"
            label="Register"
            bg="var(--primary)"
            onClick={handlePayment} />
        </div>
  

    

        <div className="Margin__global">
          <div className="Enroll__blocks">
            <div className="Enroll__block__left">
              <div className="Enroll__headingimg">
                <div className="Enroll__img">
                  <img src={piou} alt="" />
                </div>

                <div className="Enroll__title__desc">
                  <br />
                  <h2>{coursename_pioupiou}</h2>
                  <p>{description_pioupiou}</p>
                </div>
              </div>

              <div className="h4"></div>
              <h3>Course Objectives</h3>
              <div className="Enroll__objectives">
                <div className="Enroll__objectives__icon">
                  <CheckCircle />
                </div>
                <p>
                  Introduce children to basic French vocabulary and expressions
                  through fun and engaging activities.
                </p>
              </div>
              <div className="Enroll__objectives">
                <div className="Enroll__objectives__icon">
                  <CheckCircle />
                </div>
                <p>
                  Encourage interaction through songs, games, and storytelling.
                </p>
              </div>
              <div className="Enroll__objectives">
                <div className="Enroll__objectives__icon">
                  <CheckCircle />
                </div>
                <p>
                  Build confidence in speaking through repetition and practice.
                </p>
              </div>
              <div className="Enroll__objectives">
                <div className="Enroll__objectives__icon">
                  <CheckCircle />
                </div>
                <p>
                  Foster social skills by sharing, taking turns, and cooperating
                  with peers.
                </p>
              </div>
              <div className="h32"></div>
              <h3>Engaging Syllabus</h3>
              <p>
                Our comprehensive syllabus ensures an engaging learning
                experience:
              </p>
              <br />
              <Dropdown title="1. The Alphabet 🅰️" items={courseItems1} />
              <div className="h16"></div>
              <Dropdown title="2. Time and numbers 📆" items={courseItems2} />
              <div className="h16"></div>
              <Dropdown title="3. Food 🥕" items={courseItems3} />
              <div className="h16"></div>
              <Dropdown title="4. Colors 🎨" items={courseItems4} />
              <div className="h16"></div>
              <Dropdown title="5. Sport 🏏" items={courseItems5} />
              <div className="h16"></div>
              <Dropdown title="6. Animals 🐶" items={courseItems6} />
              <div className="h16"></div>
              <Dropdown title="7. The House 🏠" items={courseItems7} />
              <div className="h16"></div>
              <Dropdown title="8. School 🚌" items={courseItems8} />
              <div className="h16"></div>
              <Dropdown title="9. Weather ☀️" items={courseItems9} />
              <div className="h16"></div>
              <Dropdown title="10. Family and Friends 👫" items={courseItems10} />
              <div className="h32"></div>
              <p>
                Embark on a linguistic adventure with Le Cercle Français. Our
                Piou Piou program is the perfect start to a lifelong journey of
                language learning and cultural exploration. Enroll today and
                watch your child develop a love for French while gaining
                valuable communication skills.
              </p>
            </div>
            <div className="Enroll__block__right">
              <br />
              <h3>Become a student</h3>
              <p>
                <b>Dates:</b> {durations_pioupiou}
              </p>
              <p>
                <b>Price:</b> INR {price_pioupiou} 
              </p>
              <p>
                <b>Sessions:</b> {sessions_pioupiou}
              </p>
              <div className="Enroll__participants">
                <div className="Enroll__participants__txt">
                  <p>
                    <b>Seats left:</b>
                  </p>
                </div>

                <div className="Enroll__participants__Badge">
                  <Badge label={participants_pioupiou} />
                </div>
              </div>

              <div className="h16"></div>
              <CallToAction100 label="Register" bg="var(--primary)" onClick={handlePayment} />
            </div>
          </div>

          <div className="h32"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default EnrollPiouPiou;
