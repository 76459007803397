import React from 'react'

import './Actualite.css'
function Actualite() {
  return (
<div className="Actualite">
  <h2>Actualité</h2>
</div>
  )
}

export default Actualite