import React, { useEffect, useState } from "react";
import { db, auth } from "../../firebase";
import {
  collection,
  getDocs,
  addDoc,
  query,
  orderBy,
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import { formatDistanceToNow } from "date-fns";
import "./PostStudent.css";

function PostStudent() {
  const [students, setStudents] = useState([]);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [userName, setUserName] = useState("");
  const [coursename, setCoursename] = useState(""); // Nom du cours
  const [selectedCourse, setSelectedCourse] = useState(""); // Cours sélectionné

  useEffect(() => {
    const fetchUserName = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const userRef = doc(db, "users", user.uid);
          const userSnapshot = await getDoc(userRef);
          if (userSnapshot.exists()) {
            setUserName(userSnapshot.data().name);
            setCoursename(userSnapshot.data().coursename); // Récupérer le nom du cours
            setSelectedCourse(userSnapshot.data().selectedCourse); // Récupérer le cours sélectionné
          } else {
            console.log("No user data found");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserName();
  }, []);

  useEffect(() => {
    if (coursename && selectedCourse) {
      // Forme le nom du programme avec coursename et selectedCourse
      const programName = `${coursename} ${selectedCourse}`;
      fetchProgramStudents(programName);
      fetchProgramMessages(programName);
    }
  }, [coursename, selectedCourse]);

  // Récupère les étudiants du programme
  const fetchProgramStudents = async (programName) => {
    const studentsRef = collection(db, "Programs", programName, "students");
    const studentsSnapshot = await getDocs(studentsRef);
    const studentsList = studentsSnapshot.docs.map((doc) => doc.data());
    setStudents(studentsList);
  };

  // Récupère les messages du programme en temps réel
  const fetchProgramMessages = (programName) => {
    const messagesCollection = collection(db, "Programs", programName, "messages");
    const q = query(messagesCollection, orderBy("timestamp", "desc"));

    // Utilisation de onSnapshot pour écouter les changements en temps réel
    onSnapshot(q, (querySnapshot) => {
      const messagesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp ? doc.data().timestamp.toDate() : new Date(),
      }));
      setMessages(messagesList);
    });
  };

  const handleSendMessage = async () => {
    if (!message.trim()) return;

    const programName = `${coursename} ${selectedCourse}`;
    const messagesCollection = collection(db, "Programs", programName, "messages");

    try {
      const docRef = await addDoc(messagesCollection, {
        text: message,
        name: userName,
        timestamp: serverTimestamp(),
      });

      setMessages((prevMessages) => [
        {
          id: docRef.id,
          name: userName,
          text: message,
          timestamp: new Date(),
        },
        ...prevMessages,
      ]);

      setMessage("");
    } catch (error) {
      console.error("Error adding message: ", error);
    }
  };

  return (
    <div className="PostStudent__blocks">
      <div className="PostStudent__leftblock">
        <h4>Messages</h4>
        <input
          type="text"
          placeholder="Votre message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button onClick={handleSendMessage}>Send</button>
        <div>
          {messages.length > 0 ? (
            messages.map((msg) => (
              <div key={msg.id}>
                <p>
                  <strong>{msg.name}</strong>: {msg.text}
                </p>
                <small>{formatDistanceToNow(new Date(msg.timestamp))} ago</small>
              </div>
            ))
          ) : (
            <p>No messages yet.</p>
          )}
        </div>
      </div>

      <div className="PostStudent__rightblock">
        <h4>Students</h4>
        {students.length > 0 ? (
          students.map((student, index) => (
            <p key={index}>
              {student.name} {student.surname}
            </p>
          ))
        ) : (
          <p>No students yet.</p>
        )}
      </div>
    </div>
  );
}

export default PostStudent;
