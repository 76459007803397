import React, { useState } from "react";
import { getAuth, signOut } from "firebase/auth"; // Importez la fonction signOut de Firebase
import SidePannel from "./PortalComponents/SidePannel";
import "./AdminDashboard.css";
import Maclasse from "../portal/PortalComponents/Maclasse";
import Agenda from "../portal/PortalComponents/Agenda";
import Examens from "../portal/PortalComponents/Examens";
import Documents from "../portal/PortalComponents/Documents";
import Actualite from "../portal/PortalComponents/Actualite";
import Motdujour from "./Motdujour";
import CTAPortal from "./PortalComponents/CTAPortal";
import { Logout, Settings } from "@mui/icons-material"; // Importer l'icône
import Etudiants from "./PortalComponents/Etudiants";
import Classes from "./PortalComponents/Classes";

function AdminDashboard() {
  const [selectedMenu, setSelectedMenu] = useState("Documents");

  // Fonction pour gérer le rendu du contenu en fonction du menu sélectionné
  const renderContent = () => {
    switch (selectedMenu) {
      case "Etudiants":
        return <Etudiants />;
      case "Agenda":
        return <Agenda />;

      case "Documents":
        return <Documents />;
      case "Actualite":
        return <Actualite />;
      case "Examens":
        return <Examens />;
      case "Classes":
        return <Classes />;
      default:
        return <Maclasse />;
    }
  };

  // Fonction de déconnexion
  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth); // Déconnexion de l'utilisateur
      window.location.href = "/login"; // Redirection vers la page de connexion
    } catch (error) {
      console.error("Erreur de déconnexion:", error.message);
    }
  };

  return (
    <div className="AdminDashboard">
      <SidePannel
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
      />

      <div className="AdminDashboard__left">
        <Motdujour />
        <div className="AdminDashboard__buttons">
          <div className="AdminDashboard__margin">
            <CTAPortal label="Paramètres" icon={Settings} />
          </div>
          <CTAPortal label="Déconnexion" onClick={handleLogout} icon={Logout} />
        </div>

        {renderContent()}
      </div>
    </div>
  );
}

export default AdminDashboard;
