import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import Headerhome from "../Sections/HomeComponents/Headerhome";
import Footer from "../Sections/Footer";
import CallToAction100 from "../component/CallToAction100";
import gate from "../img/gate.gif";
import "./Login.css";
import LoadingComponent from "../component/LoadingComponent";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    const auth = getAuth();
    const db = getFirestore();
    setLoading(true);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const role = userData.role;
          setTimeout(() => {
            window.location.href = role === "admin" ? "/AdminDashboard" : "/Dashboard";
          }, 1000);
        } else {
          setError("Erreur: utilisateur non trouvé");
          setLoading(false); // Désactiver le chargement en cas d'erreur
        }
      }
    } catch (error) {
      setError("Error");
      setLoading(false); // Désactiver le chargement en cas d'erreur
    }
  };

  return (
    <div className="Login">
      {loading ? (
        <div className="Loading">
        <LoadingComponent />
        </div>
      ) : (
        <>
          <Headerhome />
          <div className="Login__layout">
            <div className="Login__left">
              <img src={gate} alt="Portal" />
            </div>
            <div className="Login__card">
              <div className="Login__inputs">
                <div className="Login__inputs_labels">
                  <h2>Welcome</h2>
                </div>
                {error && <p className="error-message">{error}</p>}
                <div className="Login__inputs__greylabels">
                  <p>Email</p>
                  <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                  <p>Password</p>
                  <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="h32"></div>
                <CallToAction100 label="Login" onClick={handleLogin} bg="var(--primary)" />
                <div className="Login__Login">
                  <p>
                    Don't have an account?{" "}
                    <a href="/our_courses">
                      <span className="V1Contact__legal">Become a student</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}

export default Login;
