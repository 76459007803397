import React, { useEffect, useState } from "react";
import db, { auth } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import "./UserProfile.css";

function UserProfile() {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const getUserRole = async () => {
        try {
          // Reference to the 'users' collection and the current user's document
          const userRef = doc(db, "users", user.uid);
          const userSnapshot = await getDoc(userRef);

          if (userSnapshot.exists()) {
            setUserData({
              uid: user.uid,
              role: userSnapshot.data().role,
              name: userSnapshot.data().name,
            });
          } else {
            console.log("No user data found");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      getUserRole();
    }
  }, []);
  return (
    <div className="UserProfile">
      {userData ? (
        <>
          <div className="UserProfile__ID">
            <p>User ID: #{userData.uid}</p>
          </div>
          <p>
            <b>{userData.name}</b>
          </p>
          <p>Connected as: {userData.role}</p>
        </>
      ) : (
        <p>Loading user data...</p>
      )}
    </div>
  );
}

export default UserProfile;
