import React from "react";
import "./CallToAction100Outlined.css";
import { ArrowForward } from "@mui/icons-material";

function CallToAction100Outlined(props) {

  const Icon = props.icon || ArrowForward 

  return (
    <button
      className={`CallToAction100Outlined ${props.disabled ? 'disabled' : ''}`} // Fusionner les classes ici

      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled} // Ajoutez la gestion du désactivation
    >
      <p>{props.label}</p>
      <div className="CallToAction_icon">
        <Icon />
      </div>
    </button>
  );
}

export default CallToAction100Outlined;
